import { SubComponents } from '@modelberry/blue-lib/react'
import { BlocksEmbeddedEntry } from './blocks-embedded-entry'
import { MdBody } from './body'
import { InlinesEntryHyperlink } from './inlines-entry-hyperlink'

export const subComponents: SubComponents = {
  BlocksEmbeddedEntry,
  Body: MdBody,
  InlinesEntryHyperlink,
}
