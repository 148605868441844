import { IconName } from '@modelberry/blue-lib/react'
import { navigate } from 'gatsby-link'
import { ContentfulAction } from '../cms/contentful-action'
import { ContentfulPage } from '../cms/contentful-page'
import { isBrowser } from './is-browser'

export interface GetActionFn {
  contentfulAction?: ContentfulAction
  /** When a page is supplied but no action, return an action to that page */
  createActionFromPage?: ContentfulPage
  preview?: boolean
}

// Convert a contentful action into a ui lib action
export const getActionFn = ({
  contentfulAction,
  createActionFromPage,
  preview,
}: GetActionFn) => {
  if (!contentfulAction && !createActionFromPage?.path) return {}
  let actionFn
  let actionHref
  let actionHeading: string | undefined
  let actionIcon: IconName | undefined

  const actionUrl = contentfulAction?.url
  const actionPath = contentfulAction?.page?.variant
    ? `/${contentfulAction.page.variant}${contentfulAction?.page?.path}`
    : contentfulAction?.page?.path
  const pagePath = createActionFromPage?.variant
    ? `/${createActionFromPage.variant}${createActionFromPage?.path}`
    : createActionFromPage?.path

  if (pagePath) {
    actionHref = pagePath
    actionFn = (
      e?: React.MouseEvent<
        HTMLAnchorElement | HTMLLIElement | HTMLButtonElement,
        MouseEvent
      >
    ) => {
      if (e) e.preventDefault()
      navigate(pagePath, { state: { preview } })
    }
    actionHeading =
      createActionFromPage?.actionHeading ||
      createActionFromPage?.pageTopic?.heading
  } else if (actionPath) {
    actionHref = actionPath
    actionFn = (
      e?: React.MouseEvent<
        HTMLAnchorElement | HTMLLIElement | HTMLButtonElement,
        MouseEvent
      >
    ) => {
      if (e) e.preventDefault()
      navigate(actionPath, { state: { preview } })
    }
    actionHeading = contentfulAction?.heading
    actionIcon = contentfulAction?.icon
  } else if (actionUrl) {
    actionFn = () => isBrowser && window.location.assign(actionUrl)
    actionHref = actionUrl
    actionHeading = contentfulAction?.heading
    actionIcon = contentfulAction?.icon
  }

  return { actionFn, actionHref, actionHeading, actionIcon }
}
