import { Navigation } from '@modelberry/blue-lib/react'
import { ContentfulAction } from '../cms/contentful-action'
import { ContentfulNavigationSegment } from '../cms/contentful-navigation-segment'
import { ContentfulPage } from '../cms/contentful-page'
import { getActionFn } from './get-action-fn'
import { isBrowser } from './is-browser'

export interface GetSegment {
  contentfulNavigationSegment?: ContentfulNavigationSegment
  preview?: boolean
}

// Convert a contentful navigation segment into a ui lib navigation segment
export const getSegment = ({
  contentfulNavigationSegment,
  preview,
}: GetSegment): Navigation => {
  const pathname = (isBrowser && window.location.pathname) || '/'
  const segment: Navigation = {
    activeId: pathname === '/' ? '/home' : pathname,
    items: [],
  }
  contentfulNavigationSegment?.actionsCollection?.items.forEach(
    (pageOrAction) => {
      if (pageOrAction.__typename === 'Page') {
        const page = pageOrAction as ContentfulPage
        const { actionFn, actionHref, actionHeading, actionIcon } = getActionFn(
          {
            createActionFromPage: page,
            preview,
          }
        )
        segment?.items?.push({
          actionFn,
          actionHref,
          heading: actionHeading,
          icon: actionIcon,
          id: page?.path === '/' ? '/home' : page?.path,
        })
      } else if (pageOrAction.__typename === 'Action') {
        const action = pageOrAction as ContentfulAction
        const { actionFn, actionHref, actionHeading, actionIcon } = getActionFn(
          {
            contentfulAction: action,
            preview,
          }
        )
        segment?.items?.push({
          actionFn,
          actionHref,
          heading: actionHeading,
          icon: actionIcon,
          id: action.page?.path === '/' ? '/home' : action.page?.path,
        })
      }
    }
  )
  return segment
}
