import { navigate } from 'gatsby'

export const uriEncodeBody = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export interface PostNetlifyForm<FormValues> {
  formName: string
  navigateTo: string
  values: FormValues
}

export const postNetlifyForm = async <FormValues,>({
  formName,
  navigateTo,
  values,
}: PostNetlifyForm<FormValues>) => {
  await fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: uriEncodeBody({
      'form-name': formName,
      ...values,
    }),
  })
  navigate(navigateTo)
}
