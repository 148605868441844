import { ApolloClient, gql } from '@apollo/client'
import { apolloPageFragment, ContentfulPage } from '../cms/contentful-page'
import { fetchListContentSource } from './fetch-list-content-source'

interface GetPage {
  client: ApolloClient<any>
  pageId?: string
  preview?: boolean
}

/**
 * Get a single full page by page id
 */
const getPage = async ({ client, pageId, preview }: GetPage) => {
  const queryResponse = await client.query({
    variables: { pageId, preview },
    query: gql`
      ${apolloPageFragment}
      query getPageDetails($pageId: String!, $preview: Boolean) {
        page(id: $pageId, preview: $preview) {
          ...Modelberry_Page
        }
      }
    `,
  })
  const page = queryResponse?.data?.page
  return page as ContentfulPage
}

export interface FetchDetailedPage {
  client: ApolloClient<any>
  includeListContentSource?: boolean
  pageId?: string
  preview?: boolean
}

/**
 * Fetch a single page and update the source of lists in the rich text field.
 *
 * This function is called when building the site with Gatsby. This also runs
 * when using the preview button once the site has been built and runs in a
 * browser.
 */
export const fetchDetailedPage = async ({
  client,
  includeListContentSource,
  pageId,
  preview,
}: FetchDetailedPage) => {
  // Fetch page without list items
  let detailedPage = await getPage({ client, pageId, preview })

  if (includeListContentSource) {
    await fetchListContentSource({ client, detailedPage, preview })

    // Fetch page again to reflect changes in the cache
    detailedPage = await getPage({ client, pageId, preview })
  }

  return detailedPage
}
