import {
  InlinesEntryHyperlinkProps,
  TextLink as TextLinkComp,
} from '@modelberry/blue-lib/react'
import { getActionFn } from '../lib/get-action-fn'

export const InlinesEntryHyperlink = ({
  anyCss,
  children,
  colors,
  model,
  options,
  subComponents,
  ...props
}: InlinesEntryHyperlinkProps) => {
  const entry: Record<string, any> | undefined =
    model?.links?.entries?.hyperlink.find(
      (entry: any) => entry.sys?.id === model?.node.data.target.sys.id
    )

  const { actionFn, actionHref } = getActionFn({
    preview: options?.preview,
    createActionFromPage: entry,
  })
  const TextLink = subComponents?.TextLink || TextLinkComp
  return (
    <TextLink
      anyCss={anyCss}
      colors={colors}
      href={actionHref}
      onClick={actionFn}
      subComponents={subComponents}
      {...props}
      optionsCss={{}}
      variant="m"
    >
      {children}
    </TextLink>
  )
}
