// IMPORTANT
// =========
//
// Tthe modelberryPluginData variable below is passed to the plugin as a string
// and without scope. The code must be in javascript. This means:
//
// - variables cannot be used
// - typescript cannot be used inside the object
//
// import { ModelberryPluginData } from '@modelberry/factory/plain'

import { ModelberryPluginData } from '@modelberry/plugin-contentful/plain'

// Root page variant is used internally, to be able to loop through the array.
// The variant field value stored in the cms for root pages is 'undefined'.
export type PageVariant =
  | 'artikel'
  | 'blog'
  | 'docs'
  | 'nieuws'
  | 'reference'
  | 'root'

// export const pageVariant = [
//   'artikel',
//   'blog',
//   'docs',
//   'nieuws',
//   'reference',
//   'root',
// ] as const
// export type PageVariant = typeof pageVariant[number]

export type TopicVariant =
  | 'block'
  | 'card'
  | 'featured'
  | 'headline'
  | 'hero'
  | 'media'
  | 'profile'
  | 'quote'
  | 'showcase'

export type ListVariant =
  | 'one-collumn'
  | 'one-collumn-swap-media'
  | 'two-collumn'
  | 'three-collumn'
  | 'four-collumn'
  | 'carousel'
  | 'single'

export const listOrderMap = ['date_DESC', 'date_ASC', 'title_ASC'] as const
export const listOrderMapNl = ['nieuwste', 'oudste', 'titel'] as const

export type ContactFormVariant = 'contact'

export const modelberryPluginData: ModelberryPluginData = {
  '@modelberry/plugin-contentful/plain': {
    validations: {
      'linkContentType-action-page': { linkContentType: ['action', 'page'] },
      'linkContentType-page': { linkContentType: ['page'] },
      'linkContentType-topic': { linkContentType: ['topic'] },
      'linkContentType-topic-page': { linkContentType: ['topic', 'page'] },
      'linkContentType-navigationSegment': {
        linkContentType: ['navigationSegment'],
      },
      'regexp-url': {
        regexp: {
          pattern:
            '^(ftp|http|https|mailto):(\\/\\/)*(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-/]))?$',
          flags: '',
        },
        message: 'Please use a valid url: https://this/is/a/valid/url',
      },
      'regexp-path': {
        message: 'Please use a valid path: /this/is/a/valid/path/with/:slug',
        regexp: {
          pattern: '^\\/[a-z0-9:\\._/~%\\-\\+&\\#\\?!=\\(\\)@]*$',
          flags: '',
        },
      },
      'unique-true': { unique: true },
      'in-list-order': {
        in: ['nieuwste', 'oudste', 'titel'],
      },
      'in-page-variant': {
        in: ['artikel', 'blog', 'docs', 'nieuws', 'reference'],
      },
      'in-topic-variant': {
        in: [
          'block',
          'card',
          'featured',
          'headline',
          'hero',
          'media',
          'profile',
          'quote',
          'showcase',
        ],
      },
      'in-list-variant': {
        in: [
          'one-collumn',
          'one-collumn-swap-media',
          'two-collumn',
          'three-collumn',
          'four-collumn',
          'carousel',
          'single',
        ],
      },
      'in-contact-form-variant': {
        in: ['contact'],
      },
      'linkMimetypeGroup-image-video': {
        linkMimetypeGroup: ['image', 'video'],
      },
      'linkMimetypeGroup-image': { linkMimetypeGroup: ['image'] },
      'in-text': { in: ['text'] },
      'size-min0-max155': { size: { max: 155, min: 0 } },
      richText: {
        nodes: {
          'embedded-entry-block': [
            {
              linkContentType: ['list', 'topic', 'page', 'contactForm'],
              message: null,
            },
          ],
        },
      },
      enabledMarks: {
        enabledMarks: ['bold', 'italic', 'underline', 'code'],
        message: 'Only bold, italic, underline, and code marks are allowed',
      },
      enabledNodeTypes: {
        enabledNodeTypes: [
          'heading-1',
          'heading-2',
          'heading-3',
          'heading-4',
          'heading-5',
          'heading-6',
          'ordered-list',
          'unordered-list',
          'hr',
          'blockquote',
          'embedded-entry-block',
          'embedded-asset-block',
          'hyperlink',
          'entry-hyperlink',
          'asset-hyperlink',
        ],
        message: 'inline entries are not allowed',
      },
      'in-feather-icon': {
        in: [
          'activity',
          'airplay',
          'alert-circle',
          'alert-octagon',
          'alert-triangle',
          'align-center',
          'align-justify',
          'align-left',
          'align-right',
          'anchor',
          'aperture',
          'archive',
          'arrow-down-circle',
          'arrow-down-left',
          'arrow-down-right',
          'arrow-down',
          'arrow-left-circle',
          'arrow-left',
          'arrow-right-circle',
          'arrow-right',
          'arrow-up-circle',
          'arrow-up-left',
          'arrow-up-right',
          'arrow-up',
          'at-sign',
          'award',
          'bar-chart-2',
          'bar-chart',
          'battery-charging',
          'battery',
          'bell-off',
          'bell',
          'bluetooth',
          'bold',
          'book-open',
          'book',
          'bookmark',
          'box',
          'briefcase',
          'calendar',
          'camera-off',
          'camera',
          'cast',
          'check-circle',
          'check-square',
          'check',
          'chevron-down',
          'chevron-left',
          'chevron-right',
          'chevron-up',
          'chevrons-down',
          'chevrons-left',
          'chevrons-right',
          'chevrons-up',
          'chrome',
          'circle',
          'clipboard',
          'clock',
          'cloud-drizzle',
          'cloud-lightning',
          'cloud-off',
          'cloud-rain',
          'cloud-snow',
          'cloud',
          'code',
          'codepen',
          'codesandbox',
          'coffee',
          'columns',
          'command',
          'compass',
          'copy',
          'corner-down-left',
          'corner-down-right',
          'corner-left-down',
          'corner-left-up',
          'corner-right-down',
          'corner-right-up',
          'corner-up-left',
          'corner-up-right',
          'cpu',
          'credit-card',
          'crop',
          'crosshair',
          'database',
          'delete',
          'disc',
          'dollar-sign',
          'download-cloud',
          'download',
          'droplet',
          'edit-2',
          'edit-3',
          'edit',
          'external-link',
          'eye-off',
          'eye',
          'facebook',
          'fast-forward',
          'feather',
          'figma',
          'file-minus',
          'file-plus',
          'file-text',
          'file',
          'film',
          'filter',
          'flag',
          'folder-minus',
          'folder-plus',
          'folder',
          'framer',
          'frown',
          'gift',
          'git-branch',
          'git-commit',
          'git-merge',
          'git-pull-request',
          'github',
          'gitlab',
          'globe',
          'grid',
          'hard-drive',
          'hash',
          'headphones',
          'heart',
          'help-circle',
          'hexagon',
          'home',
          'image',
          'inbox',
          'info',
          'instagram',
          'italic',
          'key',
          'layers',
          'layout',
          'life-buoy',
          'link-2',
          'link',
          'linkedin',
          'list',
          'loader',
          'lock',
          'log-in',
          'log-out',
          'mail',
          'map-pin',
          'map',
          'maximize-2',
          'maximize',
          'meh',
          'menu',
          'message-circle',
          'message-square',
          'mic-off',
          'mic',
          'minimize-2',
          'minimize',
          'minus-circle',
          'minus-square',
          'minus',
          'monitor',
          'moon',
          'more-horizontal',
          'more-vertical',
          'mouse-pointer',
          'move',
          'music',
          'navigation-2',
          'navigation',
          'octagon',
          'package',
          'paperclip',
          'pause-circle',
          'pause',
          'pen-tool',
          'percent',
          'phone-call',
          'phone-forwarded',
          'phone-incoming',
          'phone-missed',
          'phone-off',
          'phone-outgoing',
          'phone',
          'pie-chart',
          'play-circle',
          'play',
          'plus-circle',
          'plus-square',
          'plus',
          'pocket',
          'power',
          'printer',
          'radio',
          'refresh-ccw',
          'refresh-cw',
          'repeat',
          'rewind',
          'rotate-ccw',
          'rotate-cw',
          'rss',
          'save',
          'scissors',
          'search',
          'send',
          'server',
          'settings',
          'share-2',
          'share',
          'shield-off',
          'shield',
          'shopping-bag',
          'shopping-cart',
          'shuffle',
          'sidebar',
          'skip-back',
          'skip-forward',
          'slack',
          'slash',
          'sliders',
          'smartphone',
          'smile',
          'speaker',
          'square',
          'star',
          'stop-circle',
          'sun',
          'sunrise',
          'sunset',
          'tablet',
          'tag',
          'target',
          'terminal',
          'thermometer',
          'thumbs-down',
          'thumbs-up',
          'toggle-left',
          'toggle-right',
          'tool',
          'trash-2',
          'trash',
          'trello',
          'trending-down',
          'trending-up',
          'triangle',
          'truck',
          'tv',
          'twitch',
          'twitter',
          'type',
          'umbrella',
          'underline',
          'unlock',
          'upload-cloud',
          'upload',
          'user-check',
          'user-minus',
          'user-plus',
          'user-x',
          'user',
          'users',
          'video-off',
          'video',
          'voicemail',
          'volume-1',
          'volume-2',
          'volume-x',
          'volume',
          'watch',
          'wifi-off',
          'wifi',
          'wind',
          'x-circle',
          'x-octagon',
          'x-square',
          'x',
          'youtube',
          'zap-off',
          'zap',
          'zoom-in',
          'zoom-out',
        ],
      },
    },
  },
}
