import { useField } from 'formik'
import { AnyProps } from '@modelberry/any-element/react'
import { BinaryField, ComponentColors } from '@modelberry/blue-lib/react'

const getCheckboxErrors = (requiredMessage?: string) => (value: string) => {
  let error = ''
  if (!value && requiredMessage) {
    error = requiredMessage
  }
  return error
}

export type CheckboxFieldProps = {
  colors?: ComponentColors
  label: string
  labelActionFn?: () => void
  name: string
  requiredMessage?: string
} & AnyProps['div']

export const CheckboxField = ({
  colors,
  label,
  labelActionFn,
  name,
  requiredMessage,
  ...props
}: CheckboxFieldProps) => {
  const [field, meta] = useField({
    name,
    validate: getCheckboxErrors(requiredMessage),
  })

  const errors: string[] = []

  if (meta.error) errors.push(meta.error)

  return (
    <BinaryField
      colors={colors}
      model={{
        errorMessage: errors.length > 0 ? errors.join('/') : undefined,
        groupId: name,
        items: {
          [name]: {
            inputProps: field,
            label,
            labelActionFn,
          },
        },
      }}
      variant="checkbox"
      {...props}
    />
  )
}
