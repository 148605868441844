import { Meta } from '@modelberry/blue-lib/react'
import { ContentfulGlobals } from '../cms/contentful-globals'
import { ContentfulPage } from '../cms/contentful-page'
import { PageData } from './page-template'

export interface PageMeta {
  data?: PageData
  globals?: ContentfulGlobals
  page?: ContentfulPage
}

export const PageMeta = ({ data, globals, page }: PageMeta) => (
  <Meta
    authors={[
      page?.author?.[0],
      globals?.siteAuthor,
      data?.site?.siteMetadata?.siteBranding,
    ]}
    descriptions={[page?.pageTopic?.abstract, globals?.siteDescription]}
    headings={[page?.pageTopic?.heading, globals?.siteHeading]}
    imageSrcs={[page?.pageTopic?.media?.url, globals?.siteImage?.url]}
    keywordSets={[page?.label, globals?.siteKeywords]}
    locale="nl"
    siteVersion={data?.site?.siteMetadata?.siteVersion}
  />
)
