import { Global } from '@emotion/react'
import { Div, globalReset } from '@modelberry/any-element/react'
import { graphql, navigate } from 'gatsby'
import type { PageProps } from 'gatsby'
import {
  FooterSection,
  HeaderSection,
  UpdateButton,
} from '@modelberry/blue-lib/react'
import { ApolloClient, ApolloConsumer } from '@apollo/client'
import { useEffect, useState } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import { ContentfulPage } from '../cms/contentful-page'
import { ContentfulGlobals } from '../cms/contentful-globals'
import { fetchDetailedPage } from '../graphql-client/fetch-detailed-page'
import { isPreviewMode } from '../lib/is-preview-mode'
import { isBrowser } from '../lib/is-browser'
import { getSegment } from '../lib/get-segment'
import { subComponents } from '../components/sub-components'
import { colors } from '../theme/colors'
import { PageMain } from './page-main'
import { PageMeta } from './page-meta'

if (isBrowser) {
  smoothscroll.polyfill()
}

export type PageContext = {
  page?: ContentfulPage
  globals?: ContentfulGlobals[]
}

export type PageData = {
  site: {
    siteMetadata: {
      siteBranding: string
      siteVersion: string
    }
  }
}

export type State = {
  preview?: boolean
}

export interface PageTemplateProps
  extends PageProps<PageData, PageContext, State> {
  client: ApolloClient<any>
}

const PageTemplate = ({
  data,
  pageContext,
  client,
  location,
}: PageTemplateProps) => {
  const [previewPage, setPreviewPage] = useState<ContentfulPage>()
  const [isLoading, setIsLoading] = useState(false)
  const preview = isPreviewMode() || location.state?.preview
  useEffect(() => {
    if (preview) fetchPreview()
  }, [])
  const page = previewPage || pageContext.page
  const globals = pageContext.globals?.[0]
  const actionSegment = getSegment({
    contentfulNavigationSegment: globals?.actionSegment,
    preview,
  })
  const menuSegment = getSegment({
    contentfulNavigationSegment: globals?.menuSegment,
    preview,
  })
  const socialSegment = getSegment({
    contentfulNavigationSegment: globals?.socialSegment,
    preview,
  })
  const fetchPreview = async () => {
    setIsLoading(true)
    await client.resetStore()
    const fetchedPage = await fetchDetailedPage({
      client,
      includeListContentSource: true,
      pageId: page?.sys?.id,
      preview: true,
    })
    setPreviewPage(fetchedPage)
    setIsLoading(false)
  }
  return (
    <Div>
      {preview && (
        <UpdateButton options={{ loading: isLoading }} onClick={fetchPreview}>
          Update
        </UpdateButton>
      )}
      <Global styles={globalReset} />
      <PageMeta data={data} globals={globals} page={page} />
      <HeaderSection
        colors={colors}
        model={{
          actionSegment: actionSegment,
          brandingActionFn: () => navigate('/', { state: { preview } }),
          brandingHeading: data?.site?.siteMetadata?.siteBranding,
          menuSegment,
          socialSegment,
        }}
        options={{ preview }}
        subComponents={subComponents}
      />
      <PageMain
        colors={colors}
        model={{ page }}
        options={{ preview }}
        subComponents={subComponents}
      />
      <FooterSection
        colors={colors}
        model={{
          brandingHeading: data?.site?.siteMetadata?.siteBranding,
          menuSegment,
          siteVersion: data?.site?.siteMetadata?.siteVersion,
          socialSegment,
        }}
        subComponents={subComponents}
      />
    </Div>
  )
}

const PageWithApolloClient = (props: Omit<PageTemplateProps, 'client'>) => (
  <ApolloConsumer>
    {(client) => <PageTemplate client={client} {...props} />}
  </ApolloConsumer>
)

export default PageWithApolloClient

export const query: any = graphql`
  query {
    site {
      siteMetadata {
        siteBranding
        siteVersion
      }
    }
  }
`
