import { Div } from '@modelberry/any-element/react'
import {
  Button as ButtonComp,
  ComponentProps,
  MaxWidthSectionLayout,
  StyleObject,
} from '@modelberry/blue-lib/react'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { getActionFn } from '../../lib/get-action-fn'
import { CheckboxField } from './checkbox-field'
import { ContentfulContactForm } from './contentful-contact-form'
import { postNetlifyForm } from './post-netlify-form'
import { TextField } from './text-field'

const gapBoxStyle: StyleObject = { display: 'flex', flexDirection: 'column' }

interface FormValues {
  name: string
  email: string
  checkbox: boolean
}

export interface ContactForm {
  contentfulContactForm?: ContentfulContactForm
}

export type ContactFormProps = ComponentProps<ContactForm>['div']

export const ContactForm = ({
  anyCss,
  colors,
  model,
  subComponents,
  ...props
}: ContactFormProps) => {
  const [provideFeedback, setProvideFeedback] = useState(false)
  const initialValues: FormValues = {
    checkbox: false,
    email: '',
    name: '',
  }
  const formName = model?.contentfulContactForm?.variant || 'unknown'

  const { actionFn } = getActionFn({
    createActionFromPage: model?.contentfulContactForm?.checkboxLabelLnkPage,
  })
  const Button = subComponents?.Button || ButtonComp
  const navigateToPage = model?.contentfulContactForm?.navigateToPage
  const navigateTo = navigateToPage?.variant
    ? `/${navigateToPage.variant}${navigateToPage.path}`
    : navigateToPage?.path || '/'

  return (
    <MaxWidthSectionLayout anyCss={anyCss} {...props}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: FormValues) =>
          postNetlifyForm<FormValues>({
            formName,
            values,
            navigateTo,
          })
        }
        validateOnBlur={provideFeedback}
        validateOnChange={provideFeedback}
      >
        {() => (
          <Form
            name={formName}
            data-netlify="true"
            data-netlify-honeypot="name-field"
            style={{ width: '100%' }}
          >
            <Div anyCss={{ ...gapBoxStyle, gap: 32 }}>
              <Div anyCss={{ ...gapBoxStyle, gap: 16 }}>
                <input name="name-field" hidden />
                {model?.contentfulContactForm?.nameLabel && (
                  <TextField
                    colors={colors}
                    label={model?.contentfulContactForm?.nameLabel}
                    name="name"
                    placeholder={model?.contentfulContactForm?.namePlaceholder}
                    requiredMessage={model?.contentfulContactForm?.nameRequired}
                    variant="text"
                  />
                )}
                {model?.contentfulContactForm?.emailLabel && (
                  <TextField
                    colors={colors}
                    label={model?.contentfulContactForm?.emailLabel}
                    name="email"
                    placeholder={model?.contentfulContactForm?.emailPlaceholder}
                    requiredMessage={
                      model?.contentfulContactForm?.emailRequired
                    }
                    variant="email"
                  />
                )}
                {model?.contentfulContactForm?.checkboxLabel && (
                  <CheckboxField
                    colors={colors}
                    label={model?.contentfulContactForm?.checkboxLabel}
                    labelActionFn={() => actionFn && actionFn()}
                    name="checkbox"
                    requiredMessage={
                      model?.contentfulContactForm?.checkboxRequired
                    }
                  />
                )}
              </Div>
              <Button
                colors={colors}
                type="submit"
                variant="m"
                onClick={() => setProvideFeedback(true)}
              >
                {model?.contentfulContactForm?.sendButton}
              </Button>
            </Div>
          </Form>
        )}
      </Formik>
    </MaxWidthSectionLayout>
  )
}
