import { IconName } from '@modelberry/blue-lib/react'
import { gql } from '@apollo/client'
import { ContentfulPage } from './contentful-page'

/** @modelberry
 * - {@plugin "@modelberry/plugin-contentful/plain"}
 * - {@type action}
 * - {@displayField title}
 * - {@description Action}
 * - {@name action}
 */
export interface ContentfulAction {
  /** @modelberry {@ignore} */
  __typename?: string

  /** @modelberry {@ignore} */
  sys?: {
    id?: string
  }

  /** @modelberry
   * - {@name Titel binnen Contentful}
   * - {@required true}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Alleen gebruikt in Contentful, wordt nooit getoond op de site zelf}
   */
  title?: string

  /** @modelberry
   * - {@name Icon}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@validations in-feather-icon}
   */
  icon?: IconName

  /** @modelberry
   * - {@name Actie naam}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Naam van de actie, zonder punt aan eind}
   */
  heading?: string

  /** @modelberry
   * - {@name Actie omschrijving}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Omschrijving van de actie, voor zoekmachines}
   */
  description?: string

  /** @modelberry
   * - {@name Actie paginalink}
   * - {@type Link}
   * - {@linkType Entry}
   * - {@widgetId entryLinkEditor}
   * - {@helpText De pagina waar de actie naar linkt (of gebruik de URL)}
   * - {@validations linkContentType-page}
   */
  page?: ContentfulPage

  /** @modelberry
   * - {@name Actie URL}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText De URL waar de actie naar linkt (of gebruik de paginalink)}
   * - {@validations regexp-url}
   */
  url?: string
}

export const apolloActionFragment: any = gql`
  fragment Modelberry_Action on Action {
    __typename
    sys {
      id
    }
    page {
      __typename
      sys {
        id
      }
      path
    }
    description
    heading
    icon
    url
  }
`
