import { ApolloClient, makeReference } from '@apollo/client'
import { ContentfulList } from '../cms/contentful-list'
import { ContentfulPage } from '../cms/contentful-page'
import { listOrderMap, listOrderMapNl } from '../cms/modelberry-plugin-data'
import { fetchDetailedPages } from './fetch-detailed-pages'
import { fetchShallowPages } from './fetch-shallow-pages'

export interface UseContentSource {
  client: ApolloClient<any>
  detailedPage: ContentfulPage
  preview?: boolean
}

/**
 * If a page has a rich text section that contains a list, fetch the pages for
 * that list. The list is updated in the Apollo cache. Fetching the page again
 * from cache will show the populated list.
 */
export const fetchListContentSource = async ({
  client,
  detailedPage,
  preview,
}: UseContentSource) => {
  // Add pages to lists that have the contentSource field defined
  const blocks: ContentfulList[] =
    detailedPage.text?.links?.entries?.block || []

  // Cycle through rich text entry blocks and look for a List
  for (const list of blocks) {
    if (list.__typename !== 'List') continue
    if (!list.contentSource) continue
    if (!list.listCollection?.items) continue

    const listOrderNlIndex = listOrderMapNl.indexOf(list.orderBy || 'titel')
    const listOrder = listOrderMap[listOrderNlIndex]

    // Fetch shallow pages filtered by page variant and with list order
    const shallowPages = await fetchShallowPages({
      client,
      order: [listOrder],
      preview,
      variant: list.contentSource,
    })

    // Fetch detailed pages
    const detailedPages: ContentfulPage[] = await fetchDetailedPages({
      client,
      shallowPages,
      preview,
    })

    // Convert detailed pages to Apollo references
    const pageReferences = detailedPages
      .filter((page) => !page.hideFromList)
      .map((page) => makeReference(client.cache.identify(page as any)!))

    // Add pages to list in cache
    const id = client.cache.identify(list as any)
    client.cache.modify({
      id,
      fields: {
        listCollection: (value) => ({ ...value, items: pageReferences }),
      },
    })
  }
}
