import { Topic } from '@modelberry/blue-lib/react'
import { ContentfulAction } from '../cms/contentful-action'
import { ContentfulPage } from '../cms/contentful-page'
import { ContentfulTopic } from '../cms/contentful-topic'
import { getActionFn } from './get-action-fn'

export interface GetTopic {
  /** The Contentful Topic to be converted into a blue-lib Topic */
  contentfulTopic?: ContentfulTopic
  /** Create an action from page model */
  createActionFromPage?: ContentfulPage
  /** Add a preview query parameter to the links created */
  preview?: boolean
  /** Use author, date, label and action from the Contentful page model */
  useMetaDataFromPage?: ContentfulPage
}

// Convert a contentful topic into a ui lib topic
export const getTopic = ({
  contentfulTopic,
  createActionFromPage,
  useMetaDataFromPage,
  preview,
}: GetTopic): Topic => {
  const media = contentfulTopic?.media
  const contentTypeParts = media?.contentType?.split('/')
  const isImage = contentTypeParts?.[0] === 'image'
  const isVideo = contentTypeParts?.[0] === 'video'
  const date = Date.parse(useMetaDataFromPage?.date || '')
  const [formattedDate] = new Date(date).toLocaleString('nl').split(' ')

  let topicActionIsAction: ContentfulAction | undefined
  let topicActionIsPage: ContentfulPage | undefined
  const contentfulPageOrAction = contentfulTopic?.actionsCollection?.items?.[0]
  if (contentfulPageOrAction?.__typename === 'Page') {
    topicActionIsPage = contentfulPageOrAction
  }
  if (contentfulPageOrAction?.__typename === 'Action') {
    topicActionIsAction = contentfulPageOrAction
  }
  /**
   * Create an action from a topic
   *
   * 1) Try property createActionFromPage
   * 2) Try a topic action that is a page
   * 3) Try a topic action that is an action
   *
   */
  const { actionFn, actionHref, actionHeading, actionIcon } = getActionFn({
    createActionFromPage: createActionFromPage || topicActionIsPage,
    contentfulAction: topicActionIsAction,
    preview,
  })
  const topic: Topic = {
    abstract: contentfulTopic?.abstract,
    // abstractLinkFn
    actionFn,
    actionHeading,
    actionHref,
    actionIcon,
    author: Array.isArray(useMetaDataFromPage?.author)
      ? useMetaDataFromPage?.author.join(', ')
      : undefined,
    date: useMetaDataFromPage?.date ? formattedDate : undefined,
    embed: contentfulTopic?.embed,
    heading: contentfulTopic?.heading,
    icon: contentfulTopic?.icon,
    imageUrl: (isImage && contentfulTopic?.media?.url) || undefined,
    label: Array.isArray(useMetaDataFromPage?.label)
      ? useMetaDataFromPage?.label.join(', ').toUpperCase()
      : undefined,
    mediaDescription: contentfulTopic?.media?.description,
    videoUrl: (isVideo && contentfulTopic?.media?.url) || undefined,
  }
  return topic
}
