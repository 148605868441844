import { ApolloClient, gql } from '@apollo/client'
import { ContentfulPage } from '../cms/contentful-page'
import { PageVariant } from '../cms/modelberry-plugin-data'

export interface FetchShallowPages {
  client: ApolloClient<any>
  order?: ('date_ASC' | 'date_DESC' | 'title_ASC' | 'title_DESC')[]
  preview?: boolean
  variant?: PageVariant
}

/**
 * Because Contentful limits the size of Graphql responses (probably because of
 * caching), a shallow version of the page is fetched first. Full pages can be
 * fetched by passing the shallow pages returned here.
 */
export const fetchShallowPages = async ({
  client,
  order,
  preview,
  variant,
}: FetchShallowPages): Promise<ContentfulPage[]> => {
  // When root variant is fetched, look for variant === undefined
  const isRoot = variant === 'root'
  const where = variant
    ? {
        variant: isRoot ? undefined : variant,
        variant_exists: !isRoot,
      }
    : {}
  const pageCollection = await client.query({
    variables: { order, preview, where },
    query: gql`
      query getOverview(
        $order: [PageOrder]
        $preview: Boolean
        $where: PageFilter
      ) {
        pageCollection(where: $where, order: $order, preview: $preview) {
          items {
            ... on Page {
              __typename
              sys {
                id
              }
              path
              variant
            }
          }
        }
      }
    `,
  })
  const pages = pageCollection?.data?.pageCollection?.items
  return pages || []
}
