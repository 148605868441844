import { gql } from '@apollo/client'
import { ContentfulPage } from './contentful-page'
import { apolloTopicFragment, ContentfulTopic } from './contentful-topic'
import {
  listOrderMapNl,
  ListVariant,
  PageVariant,
  TopicVariant,
} from './modelberry-plugin-data'

/** @modelberry
 * - {@plugin "@modelberry/plugin-contentful/plain"}
 * - {@type list}
 * - {@displayField title}
 * - {@description List}
 * - {@name list}
 */
export interface ContentfulList {
  /** @modelberry {@ignore} */
  __typename?: string
  /** @modelberry {@ignore} */
  sys?: {
    id?: string
  }

  /** @modelberry
   * - {@name Titel binnen Contentful}
   * - {@required true}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Alleen gebruikt in Contentful, wordt nooit getoond op de site zelf}
   */
  title?: string

  /** @modelberry
   * - {@name Variant}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@helpText In wat voor lijst de topics worden getoond.}
   * - {@validations in-list-variant}
   */
  variant?: ListVariant

  /** @modelberry
   * - {@name Topic variant}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@helpText De weergave van de topics in de lijst.}
   * - {@validations in-topic-variant}
   */
  topicVariant?: TopicVariant

  /** @modelberry
   * - {@name Gebruik volledige breedte van de pagina}
   * - {@type Boolean}
   * - {@helpText Lijn uit met de breedte van de pagina in plaats van de breedte van de tekstkolom.}
   */
  wideView?: boolean

  /** @modelberry
   * - {@name Draai volgorde van alle topics om}
   * - {@type Boolean}
   * - {@helpText Draai de volgorde om van de tekst en afbeelding van alle topics in de lijst.}
   */
  topicReversedOrder?: boolean

  /** @modelberry
   * - {@name Sorteer lijst}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@helpText Sorteer de lijst.}
   * - {@validations in-list-order}
   */
  orderBy?: typeof listOrderMapNl[number]

  /** @modelberry
   * - {@name Bron lijst}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@helpText Vul de lijst met alle pagina's van een specifieke variant.}
   * - {@validations in-page-variant}
   */
  contentSource?: PageVariant

  /** @modelberry
   * - {@name Lijst met topics en/of pagina's}
   * - {@type Array}
   * - {@itemsType Link}
   * - {@itemsLinkType Entry}
   * - {@itemsValidations linkContentType-topic-page}
   * - {@widgetId entryLinksEditor}
   * - {@helpText Pagina's en/of topics die worden weergegeven}
   */
  listCollection?: {
    items: (ContentfulPage | ContentfulTopic)[]
  }
}

export const apolloListFragment: any = gql`
  ${apolloTopicFragment}
  fragment Modelberry_List on List {
    __typename
    sys {
      id
    }
    variant
    topicVariant
    topicReversedOrder
    orderBy
    contentSource
    wideView
    listCollection(limit: 50) {
      items {
        ...Modelberry_Topic
        ... on Page {
          __typename
          sys {
            id
          }
          author
          actionHeading
          date
          label
          path
          pageTopic {
            ...Modelberry_Topic
          }
          variant
        }
      }
    }
  }
`
