const whiteTextStyle = {
  '& > section > section': {
    '& > h1': {
      color: `var(--color-stone-000)`,
    },
    '& > p': {
      color: `var(--color-stone-000)`,
      fontWeight: 'var(--body-font-weight-bold)' as 'bold',
    },
    '& > label': {
      color: 'var(--color-stone-000)',
    },
  },
}

export const topicVariantCss = {
  hero: whiteTextStyle,
}
