import { gql } from '@apollo/client'
import { RichTextLinks } from '@modelberry/blue-lib/react'
import { apolloContactFormFragment } from '../components/contact-form/contentful-contact-form'
import { apolloAssetFragment } from './contentful-asset'
import { apolloListFragment } from './contentful-list'
import { apolloTopicFragment, ContentfulTopic } from './contentful-topic'
import { PageVariant } from './modelberry-plugin-data'

export type RichTextJson = any

/** @modelberry
 * - {@plugin "@modelberry/plugin-contentful/plain"}
 * - {@type page}
 * - {@displayField title}
 * - {@description Page}
 * - {@name page}
 */
export interface ContentfulPage {
  /** @modelberry {@ignore} */
  __typename?: string

  /** @modelberry {@ignore} */
  sys?: {
    id?: string
  }

  /** @modelberry
   * - {@name Titel binnen Contentful}
   * - {@required true}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Alleen gebruikt in Contentful, wordt nooit getoond op de site zelf}
   */
  title?: string

  /** @modelberry
   * - {@name Variant}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@helpText Paginavariant. Varianten kunnen automatisch in een lijst worden weergegeven.}
   * - {@validations in-page-variant}
   */
  variant?: PageVariant

  /** @modelberry
   * - {@name Pagina URL}
   * - {@required true}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Laatste deel van de URL naar deze pagina. Bijvoorbeeld: www.mijnsite.nl/stel-dit-deel-hier-in}
   * - {@validations regexp-path unique-true}
   */
  path?: string

  /** @modelberry
   * - {@name Verberg in lijsten en sitemap}
   * - {@type Boolean}
   * - {@helpText Publiceer de pagina, maar toon de pagina niet in lijsten of sitemaps.}
   */
  hideFromList?: boolean

  /** @modelberry
   * - {@name Pagina topic}
   * - {@type Link}
   * - {@linkType Entry}
   * - {@widgetId entryLinkEditor}
   * - {@helpText Pagina topic dat wodt gebruikt als eerste topic op de pagina, als pagina title en voor SEO}
   * - {@validations linkContentType-topic}
   */
  pageTopic?: ContentfulTopic

  /** @modelberry
   * - {@name Actietitel}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Deze title wordt gebruikt om naar deze pagina te linken, bijvoorbeeld vanuit een menu }
   */
  actionHeading?: string

  /** @modelberry
   * - {@name Tekst}
   * - {@type RichText}
   * - {@widgetId richTextEditor}
   * - {@helpText Eenvoudig opgemaakte tekstveld met kopjes en afbeeldingen}
   * - {@validations enabledMarks enabledNodeTypes richText}
   */
  text?: {
    json?: RichTextJson
    links?: RichTextLinks
  }

  /** @modelberry
   * - {@name Datum}
   * - {@type Date}
   * - {@widgetId datePicker}
   */
  date?: string

  /** @modelberry
   * - {@name Auteurs}
   * - {@type Array}
   * - {@itemsType Symbol}
   * - {@widgetId tagEditor}
   */
  author?: string[]

  /** @modelberry
   * - {@name Labels}
   * - {@type Array}
   * - {@itemsType Symbol}
   * - {@widgetId tagEditor}
   */
  label?: string[]
}

// ${apolloShallowPageFragment}
export const apolloPageFragment: any = gql`
  ${apolloAssetFragment}
  ${apolloListFragment}
  ${apolloTopicFragment}
  ${apolloContactFormFragment}
  fragment Modelberry_Page on Page {
    __typename
    sys {
      id
      firstPublishedAt
      publishedAt
    }
    path
    hideFromList
    actionHeading
    pageTopic {
      ...Modelberry_Topic
    }
    variant
    text {
      json
      links {
        assets {
          hyperlink {
            ...Modelberry_Asset
          }
          block {
            ...Modelberry_Asset
          }
        }
        entries {
          block {
            ...Modelberry_List
            ...Modelberry_Topic
            ...Modelberry_ContactForm
            ... on Page {
              __typename
              sys {
                id
              }
              author
              date
              label
              path
              actionHeading
              pageTopic {
                ...Modelberry_Topic
              }
              variant
            }
          }
          hyperlink {
            ...Modelberry_List
            ...Modelberry_Topic
            ... on Page {
              __typename
              sys {
                id
              }
              author
              date
              label
              path
            }
          }
        }
      }
    }
    date
    author
    label
  }
`
