import { ApolloClient } from '@apollo/client'
import { ContentfulPage } from '../cms/contentful-page'
import { fetchDetailedPage } from './fetch-detailed-page'

export interface FetchDetailedPages {
  client: ApolloClient<any>
  includeListContentSource?: boolean
  preview?: boolean
  shallowPages: ContentfulPage[]
}

/**
 * Because Contentful limits the size of Graphql responses (probably because of
 * caching), a shallow version of the page is fetched first. This function
 * fetches full pages individually given an array of shallow pages.
 */
export const fetchDetailedPages = async ({
  client,
  includeListContentSource,
  preview,
  shallowPages,
}: FetchDetailedPages) => {
  const detailedPages = []
  // Fetch details for each page
  for (const page of shallowPages) {
    const detailedPage = await fetchDetailedPage({
      client,
      includeListContentSource,
      pageId: page?.sys?.id,
      preview,
    })
    detailedPages.push(detailedPage)
  }
  return detailedPages
}
