import {
  Body,
  BodyProps,
  replaceNewlines,
  replaceStars,
} from '@modelberry/blue-lib/react'

export const MdBody = ({ anyCss, children, ...props }: BodyProps) => (
  <Body {...props} anyCss={[anyCss as any]}>
    {replaceStars(replaceNewlines(children))}
  </Body>
)
